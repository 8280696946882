import { RATING_BAD_MAX, RATING_AVG_MAX } from '../constants'

export const parseImagePickerJson = jsonValue => {
  const { widget_metadata } = JSON.parse(jsonValue)
  return widget_metadata.value
}

export const healthRatingLevel = rating => {
  if (rating < RATING_BAD_MAX) {
    return 'bad'
  } else if (rating < RATING_AVG_MAX) {
    return 'average'
  } else {
    return 'good'
  }
}

export const getEnv = (key, defaultValue = '') => {
  const envValue = process.env[key]
  return envValue || defaultValue
}

export const openLink = link => {
  window.open(link, '_blank') || window.location.replace(link)
}

export const createLink = (type, text, url) => {
  return {
    type,
    text,
    action: () => {
      if (window._mtm) {
        window._mtm.push({
          event: 'hyvinVointiKyselyPage',
          category: 'wellBeingPollPage',
          action: 'click to CTA',
          name: text,
        })
        openLink(url)
      }
    },
  }
}

const formNu5ContentFromOptions = questionAnswer => {
  const availableOptions = [
    {
      option: 'nu5_1',
      recommendation: 'juomaan vähintään 1-1,5 litraa nesteitä (pääosin vettä)',
    },
    {
      option: 'nu5_2',
      recommendation:
        'valitsemaan maitotuotteet vähärasvaisina tai rasvattomina (mm. juustot alle 20 prosenttisena ja nestemäiset maitotuotteet pääosin rasvattomina)',
    },
    {
      option: 'nu5_3',
      recommendation:
        'suosimaan viljojen osalta runsaskuituisia täysjyväviljatuotteita',
    },
    {
      option: 'nu5_4',
      recommendation: 'nauttimaan kalaa vähintään 2-3 aterialla viikossa',
    },
    {
      option: 'nu5_5',
      recommendation:
        'syömään punaista lihaa maksimissaan 500 g viikossa eli noin 4 aterialla viikossa ja edelleen pitämään lihavalmisteiden (kuten makkaroiden) käytön hyvin vähäisenä',
    },
    {
      option: 'nu5_6',
      recommendation:
        'minimoimaan suolan käytön valitsemalla sydänmerkkituotteita ja käyttämällä ruoanvalmistuksessa vähäsuolaisia vaihtoehtoja, kuten tuoreita yrttejä, sitruunaa ja tomaattimurskaa',
    },
  ]

  let filteredOptions

  if (!questionAnswer.options) {
    filteredOptions = availableOptions
  } else {
    filteredOptions = availableOptions.filter(
      ({ option }) =>
        questionAnswer.options.findIndex(
          userOption => userOption === option
        ) === -1
    )
  }
  if (filteredOptions.length > 2) {
    return `${filteredOptions[0].recommendation}, ${filteredOptions[1].recommendation} ja ${filteredOptions[2].recommendation}. `
  } else if (filteredOptions.length > 1) {
    return `${filteredOptions[0].recommendation} ja ${filteredOptions[1].recommendation}. `
  }
  return `${filteredOptions[0].recommendation}. `
}

const returnDynamicContentById = (id, value) => {
  switch (id) {
    case 'ac1':
      return 'tauottamaan istumista – käy hakemassa esimerkiksi pieni lasi vettä aina 30 minuutin välein tai pyri muilla tavoin katkaisemaan jatkuva paikallaan olo. '
    case 'ac2':
      return 'lisäämään kevyttä liikuskelua päivääsi – sillä on positiivinen vaikutus mm. veren sokeri- ja rasva-arvoihin. Toimisiko sinulle esimerkiksi portaiden valinta hissin sijaan tai julkisista kulkuneuvoista jääminen paria pysäkkiä aiemmin ja loppumatkan taittaminen kävellen? '
    case 'ac345':
      return 'ottamaan viikkorutiineihisi vahvemmin mukaan sydämen sykettä selkeästi kohottavaa liikuntaa. Terveysliikunnan kannalta suositus on viikkotasolla 150 minuuttia reipasta liikuntaa (pystyt vielä puhumaan) tai 75 minuuttia rasittavaa liikuntaa (puhuminen on hengästymisen vuoksi hankalaa). Myös yhdistelmä rasittavaa ja reipasta liikuntaa lasketaan. Näihin määriin ei tarvitse kuitenkaan suoraan päästä, vaan jokaisen minuutin lisäys nykyiseen on jo plussaa! '
    case 'ac6':
      return 'panostamaan lihaskuntoharjoitteluun nykyistä enemmän. Tämän ei välttämättä tarvitse olla salilla pumppaamista, vaan myös oman kehon painosi toimii hyvin vastuksena, samoin kuin esim. raskaat pihatyöt. Keskeistä on pyrkiä kuormittamaan suuria lihasryhmiä (tähän toimivat erinomaisesti mm. kyykyt ja raskaiden asioiden nostaminen). '
    case 'nu1':
      return 'muuttamaan ruokailurytmiäsi nykyistä säännöllisemmäksi. Keskeisiä hyötyjä, joita säännöllisellä ateriarytmillä ja riittävillä ateriakooilla tavoitellaan, ovat hyvän vireystilan ylläpito, aineenvaihdunnan tehostaminen sekä parempi syömisen hallinta. '
    case 'nu2':
      return 'lisäämään kasviksia, hedelmiä ja marjoja ruokavalioosi. Suositusten mukaan näitä tulisi nauttia 500g joka päivä eli noin 5-6 oman kourallisesi kokoista annosta. Muista, että kaikki kasvikset lasketaan, olivat ne sitten leivän päälle pilkottuja, ruuan sekaan ujutettuja, tuoreita, keitettyjä tai esimerkiksi osana smoothieta. '
    case 'nu3':
      return 'suosimaan kasviöljyjä ja -levitteitä. Näiden ja muiden pehmeän rasvan lähteiden, kuten pähkinöiden, siementen ja rasvaisen kalan käyttö kovan rasvan lähteiden sijaan edistää terveyttä monin tavoin. Hyödyt näkyvät kuitenkin vasta pitkällä aikavälillä, kun riskit muun muassa sydän- ja verisuonitauteihin, dementiasairauksiin ja diabetekseen liittyvään insuliiniresistenssiin pienenevät. '
    case 'nu4':
      return 'pienentämään alkoholin kulutustasi. Alkoholilla on monia negatiivisia vaikutuksia terveyteen. Vähentynyt alkoholin käyttö vaikuttaa lyhyellä aikavälillä muun muassa unen laatuun ja mielialaan positiivisesti. Pitkällä aikavälillä taas muun muassa painonhallinta helpottuu. '
    case 'nu5':
      return formNu5ContentFromOptions(value)
    default:
      return ''
  }
}

export const defineDynamicMiddleSummary = lowestScores => {
  if (!lowestScores || !lowestScores[0]) {
    return ''
  }

  const lowestScoreId = lowestScores[0][0]
  const lowestScoreValue = lowestScores[0][1]
  let content =
    'Kokeile, jos onnistuisit ' +
    returnDynamicContentById(lowestScoreId, lowestScoreValue)
  if (lowestScores.length > 1) {
    const secondLowestScoreId = lowestScores[1][0]
    const secondLowestScoreValue = lowestScores[1][1]
    content +=
      'Pyri lisäksi ' +
      returnDynamicContentById(secondLowestScoreId, secondLowestScoreValue)
  }
  return content
}
