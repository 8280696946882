import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Close } from '@styled-icons/evaicons-solid/Close'
import { Text } from '../styles/styles'
import { infoContentText } from '../sectionContent/otherContent'

const InfoModalContent = ({ toggleModal }) => {
  return (
    <>
      <HeaderSection>
        <h4>Tietoa tuloksista</h4>
        <CloseIcon title="close modal" onClick={toggleModal} />
      </HeaderSection>
      <ScrollableContent>
        <Text size="sm">{infoContentText}</Text>
      </ScrollableContent>
    </>
  )
}

InfoModalContent.propTypes = {
  toggleModal: PropTypes.func.isRequired,
}

const CloseIcon = styled(Close)`
  cursor: pointer;
  margin-left: auto;
  height: 2em;
`

const HeaderSection = styled.div`
  align-items: center;
  display: flex;
`

const ScrollableContent = styled.div`
  overflow: scroll;
`

export default InfoModalContent
