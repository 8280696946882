import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

// Assets
import { healthSubSectionRecommendationText } from '../../sectionContent/otherContent'
// Components
import { Text } from '../../styles/styles'
import HealthSubSectionHeader from './HealthSubSectionHeader'
import HealthSubSectionLinks from './HealthSubSectionLinks'

const HealthSubSectionMobile = ({ content }) => {
  const { header, summaryText, links, rating, sectionImage } = content
  const [contentOpen, setContentOpen] = useState(false)

  const mobileToggle = () => setContentOpen(!contentOpen)

  return (
    <>
      <HealthSubSectionHeader
        header={header}
        healthRating={rating}
        mobileToggle={mobileToggle}
        mobileContentOpen={contentOpen}
      />
      {contentOpen && (
        <Content>
          <SectionImage src={sectionImage} />
          {typeof summaryText === 'string' ? (
            <Text size="sm">{summaryText}</Text>
          ) : (
            summaryText
          )}
          <Text stretch bold size="sm" align="left">
            {healthSubSectionRecommendationText}
          </Text>
          <HealthSubSectionLinks links={links} />
        </Content>
      )}
    </>
  )
}

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.5em;
`

const SectionImage = styled.img`
  margin: 1em 0 2em;
  width: 50%;
  max-width: 7em;
  height: auto;
`

HealthSubSectionMobile.propTypes = {
  content: PropTypes.object.isRequired,
}

export default HealthSubSectionMobile
