import React from 'react'
import { useRouteMatch } from 'react-router-dom'
// Components
import LandingPage from './components/LandingPage'
import ErrorView from './components/ErrorView'

const App = () => {
  const match = useRouteMatch('/:responseLinkId')

  return match && match.params && match.params.responseLinkId ? (
    <LandingPage responseLinkId={match.params.responseLinkId} />
  ) : (
    <ErrorView message="virheellinen URL" />
  )
}

export default App
