import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

// Assets
import arrowDown from '../../assets/images/arrow_down.svg'

// Components
import {
  HealthSectionRatingCircle,
  MobileOnly,
  ArrowDown,
} from '../../styles/styles'

const HealthSubSectionHeader = ({
  header,
  healthRating,
  mobileToggle,
  mobileContentOpen,
  desktop,
}) => {
  return (
    <HealthSubSectionHeaderWrapper onClick={mobileToggle}>
      <HeaderText desktop={desktop}>{header}</HeaderText>
      <Wrapper desktop={desktop}>
        <HealthSectionRatingCircle rating={healthRating}>
          {healthRating}
        </HealthSectionRatingCircle>
        <MobileOnly>
          <ArrowDown
            mobileContentOpen={mobileContentOpen}
            src={arrowDown}
            alt="expand content"
          />
        </MobileOnly>
      </Wrapper>
    </HealthSubSectionHeaderWrapper>
  )
}

const HeaderText = styled.span`
  font-weight: ${({ desktop }) => (desktop ? 'bold' : 'normal')};
  text-transform: ${({ desktop }) => (desktop ? 'uppercase' : 'none')};
`

const Wrapper = styled.div`
  margin-left: ${({ desktop }) => (desktop ? '1em' : 'auto')};
  display: flex;
  align-items: center;
  margin-right: 1em;
`

const HealthSubSectionHeaderWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`

HealthSubSectionHeader.propTypes = {
  header: PropTypes.string.isRequired,
  healthRating: PropTypes.number.isRequired,
  mobileToggle: PropTypes.func,
  mobileContentOpen: PropTypes.bool,
  desktop: PropTypes.bool,
}

export default HealthSubSectionHeader
