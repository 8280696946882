import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import React from 'react'
import ReactDOM from 'react-dom'
import { HashRouter as Router } from 'react-router-dom'
import App from './App'
import { theme, AppRoot, FadingBackground } from './styles/styles'
import { ThemeProvider } from 'styled-components'
import { ModalProvider } from 'styled-react-modal'

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <AppRoot />
      <Router>
        <ModalProvider backgroundComponent={FadingBackground}>
          <App />
        </ModalProvider>
      </Router>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
)
