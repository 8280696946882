import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { breakpoints } from '../../styles/styles'

// Components
import HealthSubSectionMobile from './HealthSubSectionMobile'
import HealthSubSectionDesktop from './HealthSubSectionDesktop'

const HealthSubSection = ({ content, ix, isMobile }) => {
  return (
    <HealthSubSectionWrapper ix={ix}>
      {isMobile ? (
        <HealthSubSectionMobile content={content} />
      ) : (
        <HealthSubSectionDesktop content={content} />
      )}
    </HealthSubSectionWrapper>
  )
}

HealthSubSection.propTypes = {
  content: PropTypes.object.isRequired,
  isMobile: PropTypes.bool.isRequired,
  ix: PropTypes.number.isRequired,
}

const HealthSubSectionWrapper = styled.div`
  display: flex;
  align-items: center;

  @media (min-width: ${breakpoints['lg']}px) {
    background: ${({ theme, ix }) => (ix % 2 ? theme.lightGray : 'white')};
    padding: 3em;
  }

  @media (max-width: ${breakpoints['lg'] - 1}px) {
    padding: 0 1em;
    min-height: 2em;
    margin: 1em 1em;
    background: white;
    border-left: 4px solid ${({ theme }) => theme.main};
    flex-direction: column;
  }
`

export default HealthSubSection
