import styled, { createGlobalStyle } from 'styled-components'
import { healthRatingLevel } from '../utils/helpers'
import DaxlineProRegularTTF from '../assets/fonts/DaxlinePro-Regular.ttf'
import DaxlineProRegularWOFF from '../assets/fonts/DaxlinePro-Regular.woff'
import DaxlineProRegularEOT from '../assets/fonts/DaxlinePro-Regular.eot'
import { BaseModalBackground } from 'styled-react-modal'

export const breakpoints = {
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1920,
}

export const mediaQueries = key => {
  return style => `@media (min-width: ${breakpoints[key]}px) { ${style} }`
}

export const theme = {
  main: '#009FDA',
  blue: '#009FDA',
  secondary: '#BAB4B4',
  lightGray: '#F8F9F9',
  orange: '#F6564F',
  green: '#00D2B4',
  yellow: '#EDD605',
  fontSize: {
    small: '0.8rem',
    medium: '1rem',
    large: '1.2rem',
  },
}

export const AppRoot = createGlobalStyle`
@font-face {
  font-family: 'DaxlinePro-Regular';
  src: url(${DaxlineProRegularTTF}) format('ttf'),
  url(${DaxlineProRegularWOFF}) format('woff'),
  url(${DaxlineProRegularEOT}) format('eot');
  font-weight: 300;
  font-style: normal;
}

  html, body {
    font-family: DaxlinePro-Regular, Helvetica, Arial;
    font-size: 18px;
    color: #6E7787;
    margin: 0;
  }
`

export const Button = styled.button`
  cursor: pointer;
  font-size: ${({ theme }) => theme.fontSize.small};
  font-family: DaxlinePro, Helvetica, Arial;
  font-weight: 500;
  display: block;
  width: 100%;
  padding: 1em 1em;
  border-radius: 0.5em;
  color: white;
  border: 2px solid ${props => props.theme.main};
  background-color: ${props => props.theme.main};
  :focus {
		outline: none;
  }
  }
`

export const FadingBackground = styled(BaseModalBackground)`
  opacity: ${props => props.opacity};
  transition: opacity ease 200ms;
`

export const HealthSectionRatingCircle = styled.div`
  display: inline-block;
  width: 2.5em;
  text-align: center;
  font-size: 1em;
  margin: 0.5em;
  line-height: 2.5em;
  border-radius: 50%;
  color: white;
  background-color: ${({ theme, rating }) => {
    switch (healthRatingLevel(rating)) {
      case 'bad':
        return theme.orange
      case 'average':
        return theme.yellow
      case 'good':
        return theme.green
      default:
        return theme.main
    }
  }};
`

export const Text = styled.p`
  line-height: 1.3rem;
  font-weight: ${({ bold }) => (bold ? 'bold' : '300')};
  font-size: ${({ theme, size }) => {
    switch (size) {
      case 'sm':
        return theme.fontSize.small
      case 'md':
        return theme.fontSize.medium
      case 'lg':
        return theme.fontSize.large
      default:
        return theme.fontSize.medium
    }
  }};
  text-align: ${({ align }) => {
    switch (align) {
      case 'left':
        return 'left'
      case 'center':
        return 'center'
      default:
        return 'left'
    }
  }};
  font-style: ${({ italic }) => (italic ? 'italic' : 'normal')};
  color: ${({ color, theme }) => (color ? theme[color] : 'inherit')};
  width: ${({ stretch }) => (stretch ? '100%' : 'auto')};
`

export const DesktopOnly = styled.div`
  display: none;
  @media (min-width: ${breakpoints['lg']}px) {
    display: block;
  }
`

export const MobileOnly = styled.div`
  display: none;
  @media (max-width: ${breakpoints['lg'] - 1}px) {
    display: block;
  }
`

export const ArrowDown = styled.img`
  width: 1em;
  margin-left: 0.8em;
  display: block;
  margin-top: auto;
  margin-bottom: auto;
  transition: transform 0.2s;

  transform: ${({ mobileContentOpen }) =>
    mobileContentOpen ? 'rotate(180deg)' : 'none'};
`
