import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

// Components
import { Text } from '../styles/styles'

const ErrorView = ({ message }) => {
  return (
    <ErrorWrapper>
      <MessageWrapper>
        <h2>Tuloksia ei voida näyttää teknisen vian vuoksi</h2>
        <Text size="lg">{message}</Text>
      </MessageWrapper>
    </ErrorWrapper>
  )
}

const MessageWrapper = styled.div`
  margin: 1em;
  padding: 2em;
  background-color: ${({ theme }) => theme.main};
  border-radius: 1em;
`

const ErrorWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100vw;
  height: 100vh;
  color: white;
  background-color: ${({ theme }) => theme.lightGray};
`

ErrorView.propTypes = {
  message: PropTypes.string.isRequired,
}

export default ErrorView
