import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

// Components
import { ArrowDown, Text } from '../styles/styles'
// Assets
import arrowDown from '../assets/images/arrow_down.svg'

const MobileSummarySection = ({ summaryText }) => {
  const [mobileContentOpen, setMobileContentOpen] = useState(true)

  return (
    <MobileSummaryWrapper>
      <HeaderWrapper onClick={() => setMobileContentOpen(!mobileContentOpen)}>
        <HeaderText>Yhteenveto</HeaderText>
        <ArrowDown
          mobileContentOpen={mobileContentOpen}
          src={arrowDown}
          alt="expand content"
        />
      </HeaderWrapper>
      {mobileContentOpen && (
        <Content>
          <Text size="sm">{summaryText}</Text>
        </Content>
      )}
    </MobileSummaryWrapper>
  )
}

const Content = styled.div`
  padding: 0.5em;
`

const HeaderWrapper = styled.div`
  align-items: center;
  display: flex;
  width: 100%;
  min-height: 3em;

  > img {
    margin-left: auto;
    margin-right: 1em;
  }
`

const MobileSummaryWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 0 1em;
  min-height: 2em;
  margin: 0 1em 1em 1em;
  background: white;
  border-left: 4px solid ${({ theme }) => theme.main};
  flex-direction: column;
`

const HeaderText = styled.span`
  font-weight: normal;
`

MobileSummarySection.propTypes = {
  summaryText: PropTypes.string.isRequired,
}

export default MobileSummarySection
