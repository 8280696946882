// Libraries
import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
// Components
import HealthRatingDoughnut from './HealthRatingDoughnut'
import { Text, breakpoints } from '../styles/styles'
// Content
import backgroundImage from '../assets/images/header_image.png'
import ttLogo from '../assets/images/tt_logo_icon.png'

const DesktopSummary = ({ healthRating, summaryText }) => {
  return (
    <SummaryWrapper>
      <Logo src={ttLogo} />
      <SummaryContent>
        <SummaryHeader alignCenter large>
          Hyvinvointisi nykytila
        </SummaryHeader>
        <HealthRatingDoughnut healthRating={healthRating} />
        <DesktopSummaryText>
          <SummaryHeader>YHTEENVETO</SummaryHeader>
          <Text size="sm" align="center">
            {summaryText}
          </Text>
        </DesktopSummaryText>
      </SummaryContent>
    </SummaryWrapper>
  )
}

const Logo = styled.img`
  height: 3em;
  widht: auto;
  position: absolute;
  top: 2.15em;
  right: 2.15em;
  @media (max-width: ${breakpoints['lg'] - 1}px) {
    height: 1.5em;
    widht: auto;
    top: 2.4em;
    left: 1em;
  }
  @media (min-width: ${breakpoints['xxl']}px) {
    top: 4.15em;
    right: 4.15em;
  }
`

const DesktopSummaryText = styled.div`
  display: none;
  @media (min-width: ${breakpoints['lg']}px) {
    display: block;
  }
`

const SummaryHeader = styled.h1`
  font-size: ${({ large }) => (large ? '1em' : '0.8em')};
  text-align: center;
  margin: 0em;
  @media (min-width: ${breakpoints['lg']}px) {
    text-align: ${({ alignLeft }) => (alignLeft ? 'left' : 'center')};
    font-size: ${({ large }) => (large ? '2.3em' : '1.2em')};
    display: block;
  }

  @media (max-width: ${breakpoints['lg'] - 1}px) {
    padding: 2em 3em 1em 3em;
    background: white;
    font-size: 1.2em;
  }
`

const SummaryContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;

  @media (min-width: ${breakpoints['lg']}px) {
    width: 40%;
    min-height: 80vh;
    margin: 2em 0em 2em 4em;
  }
`

const SummaryWrapper = styled.div`
  text-align: center;
  display: flex;
  background: ${({ theme }) => theme.lightGray};
  align-items: start;

  @media (min-width: ${breakpoints['lg']}px) {
    min-height: 100vh;
    align-items: center;
    background: linear-gradient(
        rgba(255, 255, 255, 0.8),
        rgba(255, 255, 255, 0.8)
      ),
      url(${backgroundImage});
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
`

// Proptypes
DesktopSummary.propTypes = {
  summaryText: PropTypes.string.isRequired,
}

export default DesktopSummary
