import {
  healthRatingLevel,
  createLink,
  defineDynamicMiddleSummary,
} from '../utils/helpers'
import sectionImage from '../assets/images/activity_image.svg'

const links = {
  button1: createLink(
    'button',
    'Lataa Yogaia-sovellus 3€/1kk',
    'https://fi.yogaia.com/get/TTETUKYSELY'
  ),
  link1: createLink(
    'link',
    'Urheilija, vältä rasitusvammat!',
    'https://omaterveys.terveystalo.com/OmaTerveys/artikkelit-ja-blogit/Artikkelit/urheilijan-riskina-rasitusvammat/'
  ),
  link2: createLink(
    'link',
    'Kehonhuolto kunniaan – tehotreeni ei aina hikoiluta!',
    'https://omaterveys.terveystalo.com/OmaTerveys/artikkelit-ja-blogit/Artikkelit/tehotreeni-ei-aina-hikoiluta/ '
  ),
  link3: createLink(
    'link',
    'Näin aloitat liikunnan turvallisesti – nappaa asiantuntijan vinkit',
    'https://omaterveys.terveystalo.com/OmaTerveys/artikkelit-ja-blogit/Artikkelit/nain-aloitat-liikunnan-turvallisesti/'
  ),
}

const linkSections = {
  bad: [links.button1, links.link3],
  average: [links.button1, links.link2],
  good: [links.button1, links.link1],
}

const getSummary = (healthStatus, lowestScores) => {
  if (healthStatus === 'good') {
    return 'Hienoa, liikunta on selvästi tärkeä osa arkeasi! Hyvä näin, sillä pitkittyneellä paikallaan ololla on havaittu yhteys jopa 30 krooniseen sairauteen. British Journal of Sports Medicinen tekemässä tutkimuksessa (2009) osoitettiin, että huono kunto lisäsi ennenaikaisen kuoleman riskiä enemmän kuin esimerkiksi tupakointi, ylipaino tai korkea kolesteroli. Pyri jatkossakin liikkumaan monipuolisesti: Voimaharjoittelulla pystyt ylläpitämään kehosi lihasmassaa, joka luontaisesti alkaa vähentyä 3-5 % vuosikymmentä kohti jo 30 ikävuoden jälkeen. Säännöllinen aerobinen harjoittelu taas parantaa muistia ja muita keskeisiä aivotoimintoja sekä ehkäisee mm. masennusta.'
  }

  let staticStart
  let staticEnd

  if (healthStatus === 'average') {
    staticStart =
      'Monin osin aktiivisuuden tasosi vaikuttaa hyvältä, mutta myös pientä kehitettävää löytyy. '
    staticEnd =
      'Fyysinen aktiivisuus on siitä kiitollinen hyvinvoinnin indikaattori, että sen parantaminen toimii usein ponnahduslautana aiheuttaen positiivisten muutosten ”ketjureaktion” myös muilla elämän osa-alueilla.'
  } else {
    staticStart =
      'Aktiivisuuden tasossasi näyttäisi olevan parantamisen varaa. Ei kuitenkaan hätää, sillä jo pienet arkipäiväiset muutokset voivat tehdä suuren eron kokonaiskuvaan. '
    staticEnd = ''
  }
  return staticStart + defineDynamicMiddleSummary(lowestScores) + staticEnd
}

const getActivityContent = (healthRating, lowestScores) => {
  const healthStatus = healthRatingLevel(healthRating)

  return {
    header: 'Aktiivisuus',
    rating: healthRating,
    summaryText: getSummary(healthStatus, lowestScores),
    links: linkSections[healthStatus],
    sectionImage,
  }
}

export default getActivityContent
