import React from 'react'
import styled from 'styled-components'

// Components
import { breakpoints } from '../styles/styles'

const Footer = () => {
  return (
    <>
      <Container>
      <FeedbackLink href="javascript:void( window.open( 'https://form.jotform.com/202783027173352', 'blank', 'scrollbars=yes, toolbar=no, width=700, height=500' ) ) ">Anna palautetta kartoituksesta</FeedbackLink>
      <TextContainer>© Terveystalo</TextContainer>
      </Container>
    </>
  )
}

const Container = styled.div`
  background-color: white;
  text-align: center;
  color: ${({ theme }) => theme.secondary};
  padding: 3em 0em;
  font-size: 0.8rem;
  @media (max-width: ${breakpoints['lg'] - 1}px) {
    background-color: ${({ theme }) => theme.lightGray};
    font-size: 0.7rem;
    padding: 0.6em 0em 3em 0em;
  }
`
const FeedbackLink = styled.a`
  color: ${({ theme }) => theme.secondary};
`
const TextContainer = styled.p`
  margin: 0em;
`

export default Footer
