import React from 'react'
import PropTypes from 'prop-types'
import { Doughnut } from 'react-chartjs-2'
import styled from 'styled-components'

// Components
import NumericHealthRating from './NumericHealthRating'
import { breakpoints } from '../styles/styles'

const options = {
  cutoutPercentage: 80,
  responsive: true,
  maintainAspectRatio: false,
  animation: {
            duration: 2000,
          },
  tooltips: {
            enabled: false,
          },
}

const data = healthRating => ({
  datasets: [
    {
      data: [healthRating, 100 - healthRating],
      weight: 5,
      borderWidth: 0,
      backgroundColor: ['#009FDA'],
      hoverBackgroundColor: ['#009FDA'],
    },
  ],
})

const HealthRatingDougnutWrapper = styled.div`
  position: relative;
  height: 15em;
  margin: 3em 0em;
  @media (min-width: ${breakpoints['xxl']}px) {
    height: 20em;
    margin: 5em 0em;
  }
`

const HealthRatingDoughnut = ({ healthRating }) => {
  return (
    <HealthRatingDougnutWrapper>
      <Doughnut data={data(healthRating)} options={options} responsive />
      <NumericHealthRating healthRating={healthRating} />
    </HealthRatingDougnutWrapper>
  )
}

HealthRatingDoughnut.propTypes = {
  healthRating: PropTypes.number.isRequired,
}

export default HealthRatingDoughnut
