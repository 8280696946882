import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import axios from 'axios'

// Assets
import { summaryText } from '../sectionContent/otherContent'

// Components
import ErrorView from './ErrorView'
import DesktopSummary from './DesktopSummary'
import MobileSummarySection from './MobileSummarySection'
import HealthSubSection from './HealthSubSection/HealthSubSection'
import Footer from './Footer'
import { breakpoints } from '../styles/styles'
import { useMediaQuery } from 'react-responsive'

// Helpers
import parseHealthRatingFromResponse from '../utils/parseHealthRatingFromResponse'
import { healthRatingLevel } from '../utils/helpers'

// Config
import { getEnv } from '../utils/helpers'

const LandingPage = ({ responseLinkId }) => {
  const [healthRating, setHealthRating] = useState(null)
  const [error, setError] = useState(null)
  const [errorMsg, setErrorMsg] = useState(null)
  const isMobile = useMediaQuery({
    query: '(max-width: 991px)',
  })

  useEffect(() => {
    const getHealthRating = async () => {
      try {
        const { data } = await axios.get(
          `${getEnv('REACT_APP_API_BASE_URL')}/external-services/response`,
          {
            params: { responseLinkId },
          }
        )
        const healthRating = parseHealthRatingFromResponse(data.response)
        setHealthRating(healthRating)
      } catch (error) {
        setErrorMsg(
          error.name === 'ScoreError'
            ? 'Tekninen vika tulosten muodostuksessa'
            : 'Virhe haettaessa tuloksia palvelimelta'
        )
        setError(true)
      }
    }

    getHealthRating()
  }, [responseLinkId])

  const healthSubSections = () =>
    Object.values(healthRating.subSections).map((content, ix) => (
      <HealthSubSection
        isMobile={isMobile}
        key={content.header}
        ix={ix}
        content={content}
      />
    ))

  if (error) {
    return <ErrorView message={errorMsg} />
  }

  if (!healthRating) {
    return null
  }

  return (
    <>
      <DesktopSummary
        healthRating={healthRating.general}
        summaryText={summaryText[healthRatingLevel(healthRating.general)]}
      />
      <HealthSubSectionsWrapper>
        {isMobile && (
          <MobileSummarySection
            summaryText={summaryText[healthRatingLevel(healthRating.general)]}
          />
        )}
        {healthSubSections()}
      </HealthSubSectionsWrapper>
      <Footer />
    </>
  )
}

const HealthSubSectionsWrapper = styled.div`
  @media (max-width: ${breakpoints['lg'] - 1}px) {
    padding-bottom: 1em;
    background: ${({ theme }) => theme.lightGray};
  }
`

LandingPage.propTypes = {
  responseLinkId: PropTypes.string.isRequired,
}

export default LandingPage
