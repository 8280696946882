// import React from 'react'

// Components
// import MoodSummaryTextComponent from '../components/MoodSummaryTextComponent'

// Helpers
import { healthRatingLevel, createLink } from '../utils/helpers'

// Assets
import sectionImage from '../assets/images/mood_image.svg'

const links = {
  button1: createLink(
    'button',
    'Varaa aika Rela-hierontaan',
    'https://www.relahierojat.fi/'
  ),
  button2: createLink(
    'button',
    'Tutustu mielen palveluihimme',
    'https://www.terveystalo.com/fi/Palvelut/Vastaanotot/Psykologia-ja-psykoterapia/'
  ),
  link1: createLink(
    'link',
    'Aitoa vuorovaikutusta ja hyväksyntää – ystävillä on suuri vaikutus hyvinvointiin ja terveyteen',
    'https://omaterveys.terveystalo.com/OmaTerveys/artikkelit-ja-blogit/Artikkelit/hyva-ystava-pitaa-laakarin-loitolla/'
  ),
  link2: createLink(
    'link',
    'Itsemyötätunto on sallittua ja tarpeellista – viisi vinkkiä rennompaan arkeen',
    'https://omaterveys.terveystalo.com/OmaTerveys/artikkelit-ja-blogit/Artikkelit/itsemyotatunto-on-sallittua-ja-tarpeellista--viisi-vinkkia-rennompaan-arkeen'
  ),
  link3: createLink(
    'link',
    'Näin pidät huolta mielesi hyvinvoinnista',
    'https://omaterveys.terveystalo.com/OmaTerveys/artikkelit-ja-blogit/Artikkelit/video-pida-huolta-mielesi-hyvinvoinnista/'
  ),
}

const linkSections = {
  bad: [links.button2, links.link3],
  average: [links.button2, links.link2],
  good: [links.button1, links.link1],
}

const summaryText = {
  bad:
    'Tulokset viittaavat, että elämässäsi on kuormitustekijöitä, jotka vaikuttavat kokonaisvaltaiseen hyvinvointiisi. Hienoa, että teit testin – olet selvästi kiinnostunut siitä, kuinka voit! Lähes jokaisella on jossain kohdin elämää mielenterveyden ongelmia, kuten pitkittynyttä stressiä, ahdistusta tai unettomuutta. Asioita ei kannata jäädä yksin murehtimaan, vaan ongelmia voidaan onnistuneesti lievittää ja hoitaa. Tärkeää on muistaa, että apua on aina saatavilla ja mielen hyvinvoinnin palveluihin voi hakeutua matalalla kynnyksellä ilman lähetettä. Ulkopuolisen ammattilaisen kanssa asioiden pohtiminen voi avata monia mielen solmuja.',
  average:
    'Moni elämäsi osa-alue vaikuttaa olevan kunnossa: muistathan kuitenkin huolehtia jaksamisestasi myös ennaltaehkäisevästi ja painathan jarrua, jos tunnistat liiallista kuormittuneisuutta. Lähes jokaisella on jossain kohdin elämää mielenterveyden ongelmia, kuten pitkittynyttä stressiä, ahdistusta tai unettomuutta. Asioita ei kannata jäädä yksin murehtimaan, vaan ongelmia voidaan onnistuneesti lievittää ja hoitaa. Tutkimukset ovat osoittaneet, että tuen piiriin hakeutuminen varhaisessa vaiheessa kannattaa ja jo muutamakin selventävä keskustelu voi riittää. On osoitettu, että mielen hyvinvointiin liittyvät mm. kokemus yhteenkuuluvuudesta muiden kanssa sekä kyky kohdata vaikeita tunteita ja ajatuksia niiden torjumisen sijaan.',
  good:
    'Mahtavaa, huolehdit mielesi hyvinvoinnista jo ennakolta! Tulosten perusteella vaikuttaisi siltä, että nautit sinulle tärkeistä asioista ja osaat pitää kiinni myös palautumisestasi. Tutkimukset ovat osoittaneet, että ihmiset, jotka toteuttavat enemmän itselleen tärkeitä asioita, vaikka niiden tekeminen olisikin välillä haasteellista, kokevat enemmän hyvinvointia ja ovat tyytyväisempiä elämäänsä. Mielen hyvinvoinnin on osoitettu olevan vahvasti linkittynyt mm. tukeviin ja turvallisiin ihmissuhteisiin sekä merkityksen löytämiseen elämästä.',
}

// const summaryListSections = {
//   bad: [
//     'Omien vaikeidenkin tunteiden ja kokemusten tunnistaminen ja kohtaaminen niiden syrjään työntämisen sijaan',
//     'Suhtaudu itseesi ymmärtävästi ja lempeän kannustavasti kuten ystävääsi jolla on huolia',
//     'Pysähtyminen tähän hetkeen menneisyyden murehtimisen tai tulevaisuuden vatvomisen sijaan käyttämällä apuna esim. hengitystä tai ympäristön havainnointia',
//   ],
//   average: [
//     'Itselle merkityksellisten elämänsuuntien mukaiset valinnat',
//     'Suhtaudu itseesi ymmärtävästi ja lempeän kannustavasti kuten ystävääsi, jolla on huolia',
//     'Omien vaikeidenkin tunteiden ja kokemusten tunnistaminen ja kohtaaminen niiden syrjään työntämisen sijaan',
//   ],
//   good: [
//     'Itselle merkityksellisten elämänsuuntien mukaiset valinnat',
//     'Omien vaikeidenkin tunteiden ja kokemusten tunnistaminen ja kohtaaminen niiden syrjään työntämisen sijaan',
//     'Kokemusten jakaminen ja näistä iloitseminen yhdessä muiden kanssa',
//   ],
// }

const getMoodContent = healthRating => {
  const healthStatus = healthRatingLevel(healthRating)

  return {
    header: 'Mielen hyvinvointi',
    rating: healthRating,
    summaryText: summaryText[healthStatus],
    // summaryText: (
    //   <MoodSummaryTextComponent
    //     summaryListSection={summaryListSections[healthStatus]}
    //     summaryText={summaryText[healthStatus]}
    //   />
    // ),
    links: linkSections[healthStatus],
    sectionImage,
  }
}

export default getMoodContent
