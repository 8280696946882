// Content
import {
  getSleepContent,
  getActivityContent,
  getNutritionContent,
  getMoodContent,
} from '../sectionContent'
import { RATING_AVG_MAX } from '../constants'

const sortAndSplicelowestScores = lowestScores => {
  const processedLowestScores = Object.entries(lowestScores).reduce(
    (obj, [healthSection, lowScoreEntries]) => {
      lowScoreEntries.sort(
        ({ 1: lowestA }, { 1: lowestB }) => lowestA.value - lowestB.value
      )
      lowScoreEntries.splice(2)
      obj[healthSection] = lowScoreEntries

      return obj
    },
    { sl: [], nu: [], ac: [], me: [] }
  )
  return processedLowestScores
}

export const parseHealthRatingFromResponse = data => {
  try {
    const { healthSectionAnswers, ratingAverages } = data

    const lowestQuestionScores = Object.entries(healthSectionAnswers).reduce(
      (lowestScoresObj, [section, sectionAnswerObj]) => {
        Object.entries(sectionAnswerObj).forEach(
          ([questionId, questionResult]) => {
            // fields ac3, ac4 and ac5 are not taken into account individually.
            if (['ac3', 'ac4', 'ac5'].includes(questionId)) {
              return
            }

            if (questionResult.value < RATING_AVG_MAX) {
              lowestScoresObj[section].push([questionId, questionResult])
            }
          }
        )
        return lowestScoresObj
      },
      {
        sl: [],
        nu: [],
        ac: [],
        me: [],
      }
    )

    const splicedLowestRatings = sortAndSplicelowestScores(lowestQuestionScores)

    return {
      general: ratingAverages.general,
      subSections: {
        activity: getActivityContent(
          ratingAverages.subSections.ac,
          splicedLowestRatings.ac
        ),
        sleep: getSleepContent(ratingAverages.subSections.sl),
        nutrition: getNutritionContent(
          ratingAverages.subSections.nu,
          splicedLowestRatings.nu
        ),
        mood: getMoodContent(ratingAverages.subSections.me),
      },
    }
  } catch {
    const error = new Error()
    error.name = 'ScoreError'
    throw error
  }
}

export default parseHealthRatingFromResponse
