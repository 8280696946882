export const summaryText = {
  good:
    'Hienoa, pidät selkeästi huolta hyvinvoinnistasi! Aktiivisuus, uni, ravitsemus ja mielen hyvinvointi ovat kukin toisistaan riippuvaisia osa-alueita, jotka yhdessä muodostavat perustan hyvälle terveydelle. Alla olemme avanneet tulostasi kullakin osa-alueella tarkemmin sekä koonneet mukaan sinua mahdollisesti kiinnostavia sisältöjä ja palveluja.',
  average:
    'Monin osin sinulla menee jo hyvin, mutta myös parantamisen varaa löytyy! Aktiivisuus, uni, ravitsemus ja mielen hyvinvointi ovat kukin toisistaan riippuvaisia osa-alueita, jotka yhdessä muodostavat perustan hyvälle terveydelle. Alla olemme avanneet tulostasi kullakin osa-alueella tarkemmin sekä koonneet mukaan sinua mahdollisesti kiinnostavia sisältöjä ja palveluja.',
  bad:
    'Elämässäsi vaikuttaisi olevan tekijöitä, joiden vuoksi kokonaishyvinvointisi ei tällä hetkellä ole optimaalisella tasolla. Aktiivisuus, uni, ravitsemus ja mielen hyvinvointi ovat kukin toisistaan riippuvaisia osa-alueita, jotka yhdessä muodostavat perustan hyvälle terveydelle. Alla olemme avanneet tulostasi kullakin osa-alueella tarkemmin sekä koonneet mukaan sinua mahdollisesti kiinnostavia sisältöjä ja palveluja.',
}

export const healthSubSectionRecommendationText =
  'Sinua voisivat kiinnostaa seuraavat:'

export const infoContentText =
  'Hyvinvoinnin kokonaistuloksesi koostuu kunkin alla tarkemmin kuvatun osa-alueen keskiarvosta, ja siihen vaikuttaa myös painoindeksisi. Kysymysten valinnassa olemme hyödyntäneet kansallisia hyvinvoinnin suosituksia (mm. UKK-instituutin liikkumisen suositukset), tutkimusten perusteella merkitykselliseksi osoitettuja tekijöitä sekä hyvinvoinnin ammattilaistemme osaamista. Kartoituksen tarkoituksena on arvioida hyvinvointisi nykytilaa valittujen osa-alueiden kautta, joten jos sinulla on jokin akuutti terveyteesi vaikuttava oire (esim. kiputila), niin konsultoithan lääkäriäsi.'
