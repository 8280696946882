import {
  healthRatingLevel,
  createLink,
  defineDynamicMiddleSummary,
} from '../utils/helpers'
import sectionImage from '../assets/images/nutrition_image.svg'

const links = {
  button1: createLink(
    'button',
    'Varaa aika Ravitsemusstarttiin',
    'https://www.terveystalo.com/fi/Palvelut/Muut-palvelut/Ravitsemusterapia-startti/'
  ),
  link1: createLink(
    'link',
    'Saatko näitä tarpeeksi? Ravitsemusterapeutti listaa suomalaisten yleisimmät ravintoainepuutteet',
    'https://omaterveys.terveystalo.com/OmaTerveys/artikkelit-ja-blogit/Artikkelit/jodi-rauta-folaatti-d-vitamiini-c-vitamiini-ravitsemusterapeutti-listaa-suomalaisten-yleisimmat-ravintoainepuutteet/'
  ),
  link2: createLink(
    'link',
    'Totta vai tarua – 10 myyttiä ravitsemuksesta',
    'https://omaterveys.terveystalo.com/OmaTerveys/artikkelit-ja-blogit/Artikkelit/totta-vai-tarua-10-myyttia-ravitsemuksesta/'
  ),
  link3: createLink(
    'link',
    'Syötkö itsesi sairaaksi? Kehno ruokavalio kurittaa vatsaa ja suolistoa',
    'https://omaterveys.terveystalo.com/OmaTerveys/artikkelit-ja-blogit/Artikkelit/syotko-itsesi-sairaaksi-kehno-ruokavalio-kurittaa-vatsaa-ja-suolistoa/'
  ),
}

const linkSections = {
  bad: [links.button1, links.link3],
  average: [links.button1, links.link2],
  good: [links.button1, links.link1],
}

const getSummary = (healthStatus, lowestScores) => {
  if (healthStatus === 'good') {
    return 'Ruokavaliosi vaikuttaa terveyttä edistävältä ja hyvät valintasi pitävät ruokavalion aiheuttamat riskit terveydelle minimissään. Pohjoismaisen tutkimuksen perusteella kuulut selvään vähemmistöön, sillä sen mukaan suomalaisista aikuisista vain 18% syö terveyttä edistävästi. Jatka siis samaan malliin! Jos kuitenkin koet, että nykyinen ruokavaliosi ei tue terveyttäsi optimaalisesti tai mahdollista tavoitteisiisi pääsyä, auttavat ravitsemusterapeuttimme sinua mielellään sinulle yksilöllisen ruokavalion löytämisessä ja tukevat tavoitteisiisi pääsemisessä.'
  }

  let staticStart
  let staticEnd

  if (healthStatus === 'average') {
    staticStart =
      'Ruokavaliossasi on moni asia kohdillaan ja se on pääpiirteittäin terveyttä edistävä - hienoa! Pientä parantamisen varaakin on. '
  } else {
    staticStart =
      'Ruuan terveellisyys ei tällä hetkellä vaikuta olevan sinulle syystä tai toisesta kovin tärkeää. Ei kuitenkaan ole yhdentekevää mitä syöt ja juot. '
  }
  staticEnd =
    ' Voit varata ajan ravitsemusterapeutin toteuttamaan Ravitsemusstarttiin, kun haluat ammattilaisen apua syömistottumustesi muokkaamiseen hyvinvointiasi edistävämpään suuntaan.'

  return staticStart + defineDynamicMiddleSummary(lowestScores) + staticEnd
}

const getNutritionContent = (healthRating, lowestScores) => {
  const healthStatus = healthRatingLevel(healthRating)

  return {
    header: 'Ravitsemus',
    rating: healthRating,
    summaryText: getSummary(healthStatus, lowestScores),
    links: linkSections[healthStatus],
    sectionImage,
  }
}

export default getNutritionContent
