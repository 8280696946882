import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

// Assets
import arrowRight from '../../assets/images/arrow_right.svg'

// Components
import { Button, Text } from '../../styles/styles'

const HealthSubSectionLinks = ({ links }) => {
  const showLinks = () =>
    links.map(link => {
      if (link.type === 'button') {
        return (
          <Button key={link.text} onClick={link.action}>
            {link.text}
          </Button>
        )
      } else {
        return (
          <TextLinkWrapper key={link.text}>
            <Text size="sm" bold color="blue" onClick={link.action}>
              {link.text}
            </Text>
            <ArrowRight src={arrowRight} onClick={link.action} />
          </TextLinkWrapper>
        )
      }
    })

  return <LinkWrapper>{showLinks()}</LinkWrapper>
}

const ArrowRight = styled.img`
  width: 1em;
  margin-left: 1em;
`

const TextLinkWrapper = styled.div`
  cursor: pointer;
  display: flex;
  width: 100%;
  alignt-items: center;
`

const LinkWrapper = styled.div`
  width: 100%;
  margin: 1em 0em;
  > *:not(:first-child) {
    margin-top: 1em;
  }
`

HealthSubSectionLinks.propTypes = {
  links: PropTypes.array.isRequired,
}

export default HealthSubSectionLinks
