import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

// Components
import InfoIcon from './InfoIcon'
import { breakpoints } from '../styles/styles'

// Utils
import { healthRatingLevel } from '../utils/helpers'

const NumericHealthRating = ({ healthRating }) => {
  const getRatingText = rating => {
    switch (healthRatingLevel(rating)) {
      case 'bad':
        return 'Välttävä'
      case 'average':
        return 'Kohtuullinen'
      default:
        return 'Erinomainen'
    }
  }

  return (
    <NumericHealthRatingWrapper>
      <Wrapper>
        <HealthRatingValue>{healthRating}</HealthRatingValue>
        <HealthRatingDivider>/100</HealthRatingDivider>
      </Wrapper>
      <RatingText>{getRatingText(healthRating)}</RatingText>
      <InfoIcon />
    </NumericHealthRatingWrapper>
  )
}

NumericHealthRating.propTypes = {
  healthRating: PropTypes.number.isRequired,
}

const Wrapper = styled.div`
  margin-bottom: 0;
`

const HealthRatingValue = styled.span`
  font-size: 3.3em;
  line-height: 1em;
  font-weight: bold;
  color: ${({ theme }) => theme.main};
  @media (min-width: ${breakpoints['xxl']}px) {
    font-size: 4.5em;
  }
`

const RatingText = styled.span`
  font-size: 1em;
  color: ${({ theme }) => theme.secondary};
  @media (min-width: ${breakpoints['xxl']}px) {
    font-size: 1.2em;
  }
`

const HealthRatingDivider = styled.span`
  font-size: 1em;
  color: ${({ theme }) => theme.main};
  @media (min-width: ${breakpoints['xxl']}px) {
    font-size: 1.2em;
  }
`

const NumericHealthRatingWrapper = styled.div`
  position: absolute;
  top: 55%;
  left 50%;
  transform: translate(-50%, -50%);
  text-align: center;
`

export default NumericHealthRating
