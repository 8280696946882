import React, { useState } from 'react'
import Modal from 'styled-react-modal'
import styled from 'styled-components'
import { InfoCircle } from '@styled-icons/boxicons-regular/InfoCircle'
import InfoModalContent from './InfoModalContent'

const InfoIcon = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [opacity, setOpacity] = useState(0)

  const toggleModal = e => {
    setIsOpen(!isOpen)
  }

  const afterOpen = () => {
    setOpacity(1)
  }

  const beforeClose = () => {
    return new Promise(resolve => {
      setOpacity(0)
      resolve()
    })
  }

  return (
    <>
      <StyledInfoIcon title="info" onClick={toggleModal} />
      <StyledModal
        isOpen={isOpen}
        afterOpen={afterOpen}
        beforeClose={beforeClose}
        onBackgroundClick={toggleModal}
        onEscapeKeydown={toggleModal}
        opacity={opacity}
        backgroundProps={{ opacity }}
      >
        <InfoModalContent toggleModal={toggleModal} />
      </StyledModal>
    </>
  )
}

const StyledInfoIcon = styled(InfoCircle)`
  cursor: pointer;
  display: block;
  font-weight: bold;
  margin: 0.5em auto 0;
  color: ${({ theme }) => theme.secondary};
  width: 1.2em;
  height: 1.2em;
`

const StyledModal = Modal.styled`
  border-radius: 0.5em;
  width: 80vw;
  max-width: 35em;
  max-height: 80vh;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.lightGray};
  opacity: ${props => props.opacity};
  transition: opacity ease 500ms;
  padding: 1em;
`

export default InfoIcon
