import { createLink, healthRatingLevel } from '../utils/helpers'
import sectionImage from '../assets/images/sleep_image.svg'

const links = {
  button1: createLink(
    'button',
    'Tutustu Firstbeat-mittaukseen',
    'https://www.terveystalo.com/fi/Verkkokauppa/Firstbeat-Hyvinvointikartoitus-yksityisasiakkaille/'
  ),
  button2: createLink(
    'button',
    'Tutustu unipalveluihimme',
    'https://www.terveystalo.com/fi/Palvelut/Hyvinvointipalvelut/#uni'
  ),
  button3: createLink(
    'button',
    'Unihäiriöiden selvittely ja hoito',
    'https://ajanvaraus-beta.terveystalo.com/fi/ajanvaraus/yksityisasiakas/?serviceId=56270838-49c4-420d-92a0-cd5d36a1a5db'
  ),
  link1: createLink(
    'link',
    'Tiesitkö tämän hyvästä unesta? 7 yllättävää faktaa',
    'https://omaterveys.terveystalo.com/OmaTerveys/artikkelit-ja-blogit/Artikkelit/tiesitko-taman-hyvasta-unesta/'
  ),
  link2: createLink(
    'link',
    'Tunnetko unirytmisi? Näin nukut paremmin',
    'https://omaterveys.terveystalo.com/OmaTerveys/artikkelit-ja-blogit/Artikkelit/tunnetko-unirytmisi/'
  ),
  link3: createLink(
    'link',
    'Unettomuus romuttaa toimintakyvyn – hae apua ajoissa!',
    'https://omaterveys.terveystalo.com/OmaTerveys/artikkelit-ja-blogit/Artikkelit/unettomuus-romuttaa-toimintakyvyn/'
  ),
}

const linkSections = {
  bad: [links.button3, links.link3],
  average: [links.button2, links.link2],
  good: [links.button1, links.link1],
}

const summaryText = {
  bad:
    'Unessasi ja nukkumisessasi vaikuttaa olevan korjattavaa – hyvä siis, että lähdit tekemään testiä! Uni ei ole ihan koko elämä, mutta harva asia on helpompi huonosti nukutun yön jälkeen. Huonosti nukutun yön jälkeen muisti toimii tahmeammin, pinna kiristyy helpommin ja terveellisten elintapojen noudattaminen muuttuu vaikeammaksi. Unella on selkeä yhteys myös kognitiiviseen suorituskykyyn. Eräässä tutkimuksessa todettiin, että kahden viikon ajan 6 tuntia yössä nukkuvilla henkilöillä kognitiivinen suorituskyky oli yhtä heikko kuin henkilöillä, jotka pysyttelivät valveilla 2 vuorokautta yhtäjaksoisesti. Lyhyen yöunen ja viivästyneen nukkumaanmenon jälkeen oppiminen on jopa 13%:ia huonompaa kuin aikaisin nukkumaan menevillä ja riittävästi yöunta saavilla.',
  average:
    'Uni on herkkä hyvinvoinnin mittari, joten ehkä on hyvä pysähtyä tutkimaan hiukan paremmin, millaiset asiat auttaisivat sinua korjaamaan untasi ja sitä kautta voimaan vielä paremmin. Unella ja nukkumisella on laajoja vaikutuksia terveyteen, ajatteluun, mielialaan ja oppimiseen. Huonosti nukuttu yö voi heikentää fyysistä suorituskykyä sekä altistaa ylirasittumiselle. Unta tarvitaan myös stressin käsittelyyn ja esimerkiksi painonhallintaan. Eräässä tutkimuksessa havaittiin, että lyhyt yöuni oli yhteydessä runsaampaan sokerijuomien kulutukseen. Riittävän pitkä ja hyvälaatuinen uni tekee siis helpommaksi tehdä hyviä valintoja myös muun terveyden suhteen.',
  good:
    'Arvostat selvästi untasi! Olet ehkä luontaisesti hyvä nukkuja, mutta myös ymmärrät unesta huolehtimisen merkityksen. Hyvä uni auttaa monessa muussa tärkeässä asiassa, kuten liikunnassa sekä painonhallinnassa. Ammattilaispyöräilijöillä tehdyssä tutkimuksessa havaittiin selkeä yhteys radalla poljettujen kierrosten kierrosaikojen ja edeltävän unen välillä: ne, jotka nukkuivat hyvin, polkivat selkeästi nopeampia kierroksia.  Hyvin nukuttu yö tukee uusien asioiden oppimista ja kuormittavien asioiden käsittelyä. Tilapäinen unettomuus kuuluu normaaliin elämään, joten älä säikähdä, vaikka elämässä tulisikin vaiheita, jolloin unen päästä kiinnisaaminen tai unessa pysyminen olisivat vaikeutuneita. Silloin kannattaa vilkaista univalmentajiemme vinkit unen huoltoon.',
}

const getSleepContent = healthRating => {
  const healthStatus = healthRatingLevel(healthRating)

  return {
    header: 'Uni',
    rating: healthRating,
    summaryText: summaryText[healthStatus],
    links: linkSections[healthStatus],
    sectionImage,
  }
}

export default getSleepContent
