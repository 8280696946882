import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

// Components
import HealthSubSectionLinks from './HealthSubSectionLinks'
import HealthSubSectionHeader from './HealthSubSectionHeader'
import { Text } from '../../styles/styles'

const HealthSubSectionDesktop = ({ content }) => {
  const { header, summaryText, links, rating, sectionImage } = content

  return (
    <Wrapper>
      <HeaderWrapper>
        <HealthSubSectionHeader desktop header={header} healthRating={rating} />
      </HeaderWrapper>
      <ContentWrapper>
        <SectionImageWrapper>
          <SectionImage src={sectionImage} />
        </SectionImageWrapper>
        <ContentSection>
          {typeof summaryText === 'string' ? (
            <Text size="sm">{summaryText}</Text>
          ) : (
            summaryText
          )}
        </ContentSection>
        <LinksSection>
          <HealthSubSectionLinks links={links} />
        </LinksSection>
      </ContentWrapper>
    </Wrapper>

  )
}

const SectionImage = styled.img`
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 55%;
`
const SectionImageWrapper = styled.div`
  flex-basis: 25%;
  -ms-flex: 0 25%;
`

const ContentSection = styled.div`
  flex-basis: 50%;
  -ms-flex: 0 50%;
  padding: 0 3em;
  width: 100%
`

const LinksSection = styled.div`
  flex-basis: 25%;
  -ms-flex: 0 25%;
  align-self: flex-start;
`
const Wrapper = styled.div`
width: 100%;
`

const HeaderWrapper = styled.div`
  margin-left: 25%;
  padding-left: 1.5em;

`
const ContentWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`

HealthSubSectionDesktop.propTypes = {
  content: PropTypes.object.isRequired,
}

export default HealthSubSectionDesktop
